
import "deep-chat";
import { Component, Vue } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { JnAiService } from "@/services/ai.service";
import { Testata } from "@/models/testata.model";
import { CategoriesJuranet, Sections } from "@/models/enums.model";
import { DocumentSource } from "@/models/ai/document.source.model";
import MetaInfo from "vue-meta";
import { Conversation } from "@/models/ai/conversation.model";
import { History, isChatRequest } from "@/models/ai/document.history.model";
import moment from "moment";
import { Response } from "deep-chat/dist/types/response";
import { DeepChat } from "deep-chat";
import { RequestDetails } from "deep-chat/dist/types/interceptors";
import { EsService } from "@/services/es.service";
import { SearchData } from "@/models/search.model";
import EventBus from "@/services/event.bus";
import JnPaywall from "@/views/auth/Paywall.vue";
import { CrmInfo } from "@/models/crm/crmInfo.model";
import { ChatRequest, Filter } from "@/models/ai/chat_request.model";
import { ChatGeneratorResponse } from "@/models/ai/chat_response.model";
import { Document } from "@/models/document.model";
import JnSearchResultElement from '@/views/search/SearchResultElement.vue';
import { MessageContent } from "deep-chat/dist/types/messages";
import JnProgress from "@/views/common/Progress.vue";
import { CategoriesNormativa, CategoriesGiurisprudenza, CategoriesPrassiJuranet } from "@/models/enums.model";
import { Picklist } from "@/models/picklist.model";
import saveAs from "file-saver";
import { BaseAnalysis } from "@/models/ai/document.analysis.model";
import { Utils } from "@/services/utils";
import htmlToDocx from 'html-to-docx-typescript';
import MarkdownIt from 'markdown-it';

@Component({
  components: {
    JnPaywall,
    JnSearchResultElement,
    JnProgress
  },
  metaInfo(this: JnAiChat): MetaInfo {
    return { title: "JuraNews - AI Chat" };
  },
})
export default class JnAiChat extends Vue {
  testata?: Testata;

  paywallOk = false
  jwtToken = "";

  documents: Document[] = []

  // Loading e alerting
  loading = false
  alert = false
  alertMessage = ""
  alertTimeout = 8000

  // Filtri
  categorie: Picklist[] = [];
  optCategoria = null as unknown as Picklist;
  optDataDa = '';
  optDataDaFormatted = '';
  optDataA = '';
  optDataAFormatted = '';
  menuDataDa = false;
  menuDataA = false;
  optAnno = new Picklist({ value: '', label: '' });

  // Feedback
  showNegativeFeedback = false
  rating = 5
  ratingComment = ""
  valid = false
  idForFeedback?: string
  analysisFeedback = false

  showDisclaimer = false

  conversations: Conversation[] = [];
  currentConversation?: Conversation;

  documentAnalysisRequest = false;
  promptTemplate = "";
  maxTokens = 1024
  selectedDocumentForAnalysis?: DocumentSource;

  currentMessageId = 0

  parser = new DOMParser();
  md = new MarkdownIt();

  connect = Vue.observable({
    url: process.env.VUE_APP_JURANEWS_AI_GENERATOR_SERVICES_URL + "/chat",
    method: "POST",
    headers: {
      apikey: undefined
    },
    additionalBodyProps: {
      llm_model_name: process.env.VUE_APP_GENERATION_MODEL_NAME,
      relevance_threshold: 0.3,
      conversation_id: undefined
    },
  })
  avatars = {
    default: {
      styles: {
        position: "left"
      }
    },
    ai: {
      src: "/img/ai-chat-bot.png"
    },
    user: {
      src: "/img/ai-chat-user.jpg"
    }
  }
  messageStyles = {
    default: {
      shared: {
        bubble: {
          maxWidth: "95%",
          backgroundColor: "unset",
          width: '100%',
          marginTop: "10px",
          marginBottom: "10px"
        }
      },
      user: {
        bubble: {
          marginLeft: "0px",
          color: "black",
          fontFamily: 'Book Antiqua',
          fontSize: '16px',
        }
      },
      ai: {
        outerContainer: {
          backgroundColor: "rgba(247,247,248)",
          borderTop: "1px solid rgba(0,0,0,.1)",
          borderBottom: "1px solid rgba(0,0,0,.1)"
        },
        fontFamily: 'Book Antiqua',
        fontSize: '16px',
      },
      loading: {
        bubble: {
          backgroundColor: "#e52024",
          fontSize: "20px",
          color: "white"
        }
      }
    }
  }
  errorMessages = {
    displayServiceErrorMessages: true,
    overrides: {
      default: "Si è verificato un errore inaspettato! Riprovare di nuovo",
      service: "Errore nell'invocazione del servizio!",
      speechToText: "Errore nel microfono!"
    }
  }
  history: any[] = []
  introPanelStyle = '{"backgroundColor": "#ffffff"}'
  inputAreaStyle = '{"backgroundColor": "#fad1d2"}'
  textInput = {
    styles: {
      text: {
        color: "black"
      },
      container: {
        minHeight: "80px",
        maxHeight: "150px",
        maxWidth: "1000px",
        backgroundColor: "#f5f9ff"
      },
      focus: {
        border: "2px solid #e52024"
      },
    },
    placeholder: {
      text: "Scrivi qui la tua domanda",
      style: {
        color: "#666666",
        fontFamily: 'Book Antiqua',
        fontSize: '16px',
      }
    }
  }
  submitButtonStyles = {
    submit: {
      container: {
        default: {
            color: "white",
            backgroundColor: "#e62530",
            height: "1.78em",
            width: "3em",
            fontSize: "0.95em",
            fontFamily: 'Book Antiqua',
            paddingTop: '3px',
            paddingBottom: '3px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '4px',
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
          }
      },
      text: {
        content: "INVIA",
        styles: {
          default: {
            color: "white",
          }
        }
      }
    },
    loading: {
      container: {
        default: {
            color: "white",
            backgroundColor: "#aaaaaa",
            width: "10em",
            paddingBottom: '2px',
          }
      },
      text: {
        content: "Sto elaborando...",
        styles: {
          default: {
            color: "white",
          }
        }
      }
    },
    disabled: {      
      container: {
        default: {
          cursor: "auto",
          backgroundColor: "#777777",
        }
      },
    }
  }

  htmlClassUtilities = {
    feedback: {
      styles: {
        default: {
          display: 'flex'
        }
      }
    },
    text: {
      styles: {
        default: {
          width: '100%',
          paddingTop: '5px',
          textAlign: 'justify',
          fontFamily: 'Book Antiqua',
          fontSize: '16px',
        }
      }
    },
    'feedback-icon': {
      styles: {
        default: { width: '25px', height: '25px', cursor: 'pointer' },
        hover: { backgroundColor: '#d1d1d1' },
      },
    },
    'feedback-answer-positive': {
      events: {
        click: (event: Event) => this.sendPositiveAnswerFeedback(event)
      }
    },
    'feedback-answer-negative': {
      events: {
        click: (event: Event) => this.showNegativeAnswerFeedback(event)
      }
    },
    'feedback-analysis-positive': {
      events: {
        click: (event: Event) => this.sendPositiveAnalysisFeedback(event)
      }
    },
    'feedback-analysis-negative': {
      events: {
        click: (event: Event) => this.showNegativeAnalysisFeedback(event)
      }
    },
    'document-action': {
      events: {
        click: (event: Event) => this.documentAction(event)
      },
    },
    'copy-answer-clipboard': {
      styles: {
        default: { width: '20px', height: '20px', cursor: 'pointer', paddingTop: '3px' },
        hover: { backgroundColor: '#d1d1d1' },
      },
      events: {
        click: (event: Event) => this.copyAnswerToClipboard(event)
      },
    },
    'export-word': {
      styles: {
        default: { width: '30px', height: '30px', cursor: 'pointer' },
        hover: { backgroundColor: '#d1d1d1' },
      },
      events: {
        click: (event: Event) => this.exportWord(event)
      },
    },
    button: {
      styles: {
        default: {
          position: 'relative',
          backgroundColor: "#e52024",
          color: "white",
          border: 'none',
          borderRadius: 0,
          padding: '0px 0px',
          fontSize: '12px',
          fontFamily: 'Book Antiqua',
          cursor: 'pointer',
          textAlign: 'center',
          display: 'inline-block',
          transition: 'background-color 0.3s',
          width: '90px',
          height: '30px',
          letterSpacing: '2px',
          marginLeft: '10px',
        },
        hover: {
          backgroundColor: '#f4a4a5'
        }
      }
    },
    'corner-square': {
      styles: {
        default: {
          position: 'absolute', /* Posizionamento assoluto rispetto al pulsante */
          top: '-5px', /* Regola la posizione verticale del quadrato */
          right: '-5px', /* Regola la posizione orizzontale del quadrato */
          backgroundColor: '#ffffff', /* Colore di sfondo del quadrato */
          color: '#e52024', /* Colore del testo all'interno del quadrato */
          padding: '2px', /* Spaziatura interna del quadrato */
          fontSize: '8px', /* Dimensione del testo all'interno del quadrato */
          //fontWeight: 'bold', /* Spessore del testo */
          border: '1px solid #e52024', /* Bordo del quadrato */
          borderRadius: '3px', /* Leggero arrotondamento degli angoli del quadrato */
          pointerEvents: 'none'
        }
      }
    }
  }


  parseSources(sources: string[]): DocumentSource[] {
    if (!sources || (sources.length == 1 && sources[0].trim() == "")) {
      return []
    }
    else {
      const sourcesIds = new Map<string, DocumentSource>()
      for (const s of sources) {
        const [idSource, titleSection, sectionPart] = s.split('§§§');
        let [id, source] = ["", ""]
        let title = ""
        let section = ""
        if (titleSection) {
          if (sectionPart) {
            section = section.replace("#", "").trim();
          }
          else {
            [title, section] = titleSection.split('#')
            if (title) {
              title = title.replace("$$$", "").trim();
            }
            if (section) {
              section = section.replace("|||", "").trim();
            }
          }
        }
        if (idSource) {
          [id, source] = idSource.trim().split('#');
          if (!sourcesIds.has(id)) {
            sourcesIds.set(id, new DocumentSource(
              id,
              source,
              title,
              section
            ))
          }
          else {
            const docSource = sourcesIds.get(id)
            if (docSource && !docSource.sections?.includes(section)) {
              docSource.sections += ", " + section
            }
          }
        }
      }
      return Array.from(sourcesIds.values())
    }
  }

  // Filters
  saveDataDa(date: any) {
    this.optDataDaFormatted = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    this.menuDataDa = false;
    this.optAnno = new Picklist({ value: '', label: '' });
  }
  saveDataA(date: any) {
    this.optDataAFormatted = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    this.menuDataA = false;
    this.optAnno = new Picklist({ value: '', label: '' });
  }
  parseDate(date: string) {
    return date ? moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
  }

  // Events management
  onError(error: any) {
    error = JSON.parse(error)
    if (error.detail == "Unauthorized") {
      this.paywallOk = false;
      EventBus.$emit("check-authentication")
    } else {
      this.alertMessage = "Errore: '" + error.detail;
      this.alert = true;
    }
  }

  // Request & Response interceptors
  async getDocument(id: string, source: string, withText: boolean): Promise<Document | undefined> {
    const searchData = new SearchData({
      index: source == "juranet" ? process.env.VUE_APP_JURANET_INDEXES! : process.env.VUE_APP_JURANEWS_INDEXES!,
      id: id,
      size: 1,
      from: 0,
      withText: withText,
    });
    try {
      const document = await EsService.getDoc(searchData, this.jwtToken)
      return document
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.paywallOk = false;
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nel caricamento del documento '" + id + "': '" + error;
        this.alert = true;
      }
    }
  }

  searchFilter() {
   /* let categories: string[] = []
    for (const cat of this.optCategorie) {
      console.log(cat)
      if (cat.value == "1") {
        categories = [...categories, ...CategoriesNormativa];
      }
      if (cat.value == "2") {
        categories = [...categories, ...CategoriesGiurisprudenza];
      }
      if (cat.value == "3") {
        categories = [...categories, ...CategoriesPrassiJuranet];
      }
    }*/
    const filter: Filter = {}

    // Se non sono stati selezionate tuttle le categorie, imposta il filtro per le categorie, altrimenti lascia libero
   /* if (this.optCategorie.length < 3) {
      filter.id_categoria = categories.map(num => parseInt(num, 10));
    }*/


    if (this.optDataDa && this.optDataA) {
      filter.data_documento = { "$between": [this.optDataDa, this.optDataA] }
    }
    else if (this.optDataDa) {
      filter.data_documento = { "$gte": this.optDataDa }
    }
    else if (this.optDataA) {
      filter.data_documento = { "$lte": this.optDataA }
    }
    return filter;
  }

  async responseInterceptor(message: any): Promise<Response> {
    return this.processResponseMessage(message);
  }
  async requestInterceptor(message: RequestDetails): Promise<RequestDetails> {
    return this.processRequestMessage(message);

  }
  async processRequestMessage(message: any): Promise<RequestDetails> {
    message.headers.apikey = this.jwtToken;
    if (this.currentConversation) {
      message.body.conversation_id = this.currentConversation.conversationId;
    }
    if (this.documentAnalysisRequest && this.selectedDocumentForAnalysis) {
      message.body.max_tokens = this.maxTokens
      // Recupero il documento da Elastic
      const doc = await this.getDocument(this.selectedDocumentForAnalysis.id, this.selectedDocumentForAnalysis?.source, true);
      if (doc && doc.text) {
        this.selectedDocumentForAnalysis.text = Utils.extractContent(doc?.text);
      }
      this.selectedDocumentForAnalysis.id_category = String(doc?.idCategory);

      message.body.document = this.selectedDocumentForAnalysis?.toJson()

      message.body.prompt_template = this.promptTemplate
      message.body.request_type = "document_analysis"


      this.documentAnalysisRequest = false
      this.promptTemplate = ""
    }
    else {
      message.body.max_tokens = 1024
      message.body.request_type = "search_with_answer"
      message.body.filter = this.searchFilter()
      message.body.stream = false
      message.body.collection_name = this.optCategoria.value
    }

    this.currentMessageId++;
    return message;
  }

  async processResponseMessage(message: any): Promise<Response> {
    if (!this.currentConversation) {
      const usernameFromCookie = String(this.$cookies.get("username"));
      this.currentConversation = new Conversation(
        usernameFromCookie,
        message.conversation_id)
    }
    let messageHtml = ""
    let errorMessage = ""
    try {
      if (message.detail) {
        message.text = message.detail
      }
      else {
        // Se si tratta di una analisi di documento
        if (message.document_analysis) {
          const analysis = message.document_analysis as BaseAnalysis
          const wordId = this.currentMessageId + "#word"
          messageHtml = analysis.verified ? `<table class="text"><tbody><tr>` +
            `<td style="text-align:left;"><img id="` + wordId + `" src="/img/word.png" class="export-word" /></td>` +
            `<td style="text-align:right;"><img src="/img/checked.png" height="25px" width="25px" style="margin-right:10px">Verificato dalla redazione</img></td>` +
            `</tr></tbody></table>` : `<table style="width: 100%;"><tbody><tr>` +
            `<td style="text-align:left;"><img id="` + wordId + `" src="/img/word.png" class="export-word" /></td>` +
            `<td style="text-align:right;"><div style="color: #e62530"><b>Non verificato dalla redazione</b></div>` +
          `</tr></tbody></table>`
          const wordExportId = this.currentMessageId + "#" + message.documents[0].metadata.source + "#" + analysis.prompt_template + "#" + analysis.verified + "#worddoc"
          messageHtml += `<div id="` + wordExportId + `" class="text">`
          if (analysis.prompt_template == "legal.cassation_sentence_analyzer") {
            messageHtml += Utils.giurisprudenzaAnalysisToHtml(analysis, false)
          }
          else if (analysis.prompt_template == "legal.summarization_generic") {
            messageHtml += Utils.genericSummaryToHtml(analysis, false)
          }
          else if (analysis.prompt_template == "legal.maximizer") {
            messageHtml += Utils.maximsAnalysisToHtml(analysis, false)
          }
          messageHtml += `</div>`
          const positiveFeedbackId = analysis.id + "#positive"
          const negativeFeedbackId = analysis.id + "#negative"
          messageHtml += `<img id="` + positiveFeedbackId + `" class="feedback-icon feedback-analysis-positive padding-top:20px" src="/img/thumb-up.png">
                          <img id="` + negativeFeedbackId + `" class="feedback-icon feedback-analysis-negative" src="/img/thumb-down.png">`
        }
        // Se invece si tratta di una risposta a una domanda
        else {
          let documents = []
          let headerHtml = ""
          let contentHtml = ""
          const copyMessageId = this.currentMessageId + "#copy"
          const copyHtml = `<td style="text-align:right"><img id="` + copyMessageId + `" class="copy-answer-clipboard" src="/img/copy.png" style="margin-right:10px"></td>`
          const positiveFeedbackId = message.generation_id + "#positive"
          const negativeFeedbackId = message.generation_id + "#negative"

          documents = message.documents ? message.documents : []
          contentHtml = this.md.render(message.answer)

          headerHtml = `<table class="text"><tbody><tr><td style="text-align: left" class="feedback">` +
            ` <img id="` + positiveFeedbackId + `" class="feedback-icon feedback-answer-positive" src="/img/thumb-up.png">
                  <img id="` + negativeFeedbackId + `" class="feedback-icon feedback-answer-negative" src="/img/thumb-down.png" style="padding-left: 3px"></td>` +
            (documents.length > 0 ? copyHtml : "") +
            ` </tr></tbody></table>`
          const textMessageId = this.currentMessageId + "#text"

          let sourcesTable = ''
          let htmlSources = ''
          console.log(message.sources)
          const sources = this.parseSources(message.sources)
          for (const source of sources) {
            const doc = await this.getDocument(source.id, source.source, false)
            if (doc) {
              const sezione = source.sections ? source.sections : "-"
              htmlSources += `
              <tr>
                <td style="text-align: left;"><a href="/docs/juranet/` + source.id + `" target="_blank" style="font-family:'Book Antiqua';font-size: 16px,">` + source.title + `</a></td>
                <td style="text-align: left; padding-left: 10px;font-family:'Book Antiqua';font-size: 16px,">` + sezione + `</td>
                <td style="text-align: right !important">`

              const maximizerAnalysis = await JnAiService.analysisCheck(
                process.env.VUE_APP_GENERATION_MODEL_NAME!,
                "legal.maximizer",
                { id: source.id, source: source.source, id_category: String(doc.idCategory), text: doc?.content } as DocumentSource, this.jwtToken)
              if (CategoriesGiurisprudenza.includes(doc.idCategory) && maximizerAnalysis.rate == 1 && !doc.hasMassime()) {
                htmlSources += `
                    <button id="` + source.id + "#" + source.source + "#" + source.title + "#" + doc.idCategory + `#maximise" class="button document-action">
                      MASSIME
                      <span class="corner-square">AI</span>
                    </button>`
              }

              const cassationSentenceAnalysis = await JnAiService.analysisCheck(
                process.env.VUE_APP_GENERATION_MODEL_NAME!,
                "legal.cassation_sentence_analyzer",
                { id: source.id, source: source.source, id_category: String(doc.idCategory), text: doc?.content } as DocumentSource, this.jwtToken)
              if (CategoriesGiurisprudenza.includes(doc.idCategory) && cassationSentenceAnalysis.rate == 1) {
                htmlSources += `
                      <button style="marign-left: 15px !important" id="` + source.id + "#" + source.source + "#" + source.title + "#" + doc.idCategory + `#summarize" class="button document-action">
                        SINTESI
                        <span class="corner-square">AI</span>
                      </button>`
              }
              else {
                htmlSources += "<td></td>"
              }
            }
            htmlSources += `</tr>`
          }
          if (sources && sources.length > 0) {
            sourcesTable += `
              <hr class="solid">
              <table class="text" >
                  <thead>
                      <tr>
                          <th style="text-align: left;">Fonte</th>
                          <th style="text-align: left; padding-left: 10px">Sezione</th>
                          <th style="text-align: right; padding-left: 10px"></th>
                      </tr>
                  </thead>
                  <tbody>` +
              htmlSources + `                  
                  </tbody>
              </table>`
          }
          this.documents = []
          const docs = new Map<number, Document>();
          for (const doc of documents) {
            const [idJuranews, source] = doc.metadata.source.split('#')
            const idJuranewsNumber = Number(idJuranews);
            if (!docs.has(idJuranewsNumber)) {
              const searchData = new SearchData({
                index: source == "juranet" ? process.env.VUE_APP_JURANET_INDEXES! : process.env.VUE_APP_JURANEWS_INDEXES!,
                id: idJuranews,
                size: 1,
                from: 0,
                withText: false,
              });
              const document = await EsService.getDoc(searchData, this.jwtToken)
              if (document) {
                docs.set(idJuranewsNumber, document)
              }
            }
          }
          this.documents = [...docs.values()]

          messageHtml = headerHtml + `<div id="` + textMessageId + `"" class="text">` + contentHtml + `</div>` + sourcesTable

        }
      }
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.paywallOk = false;
        EventBus.$emit("check-authentication")
      } else {
        errorMessage = "Errore nel processing della risposta: " + error
        this.alertMessage = errorMessage
        this.alert = true;
      }
    }

    if (errorMessage != "") {
      message.text = errorMessage
      message.html = ""
    }
    else {
      message.html = messageHtml
      message.text = ""

      // Se è la prima risposta di una nuova conversazione, ricarico le conversazioni salvate
      if (this.currentMessageId == 1) {
        await this.updateConversations()
      }
      this.currentMessageId++
    }
    return message;
  }

  // History and conversations
  async deleteConversation(conversation: Conversation) {
    try {
      await JnAiService.deleteUserConversation(conversation.conversationId, this.jwtToken)
      this.updateConversations()
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.paywallOk = false;
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nella cancellazione della conversazione";
        this.alert = true;
      }
    }
  }

  async updateConversations() {
    try {
      this.conversations = await JnAiService.getUserConversations(this.jwtToken)
      for (const conversation of this.conversations) {
        if (this.currentConversation && this.currentConversation.conversationId == conversation.conversationId) {
          conversation.selected = true;
        }
      }
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.paywallOk = false;
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nel caricamento delle conversazioni: " + error;
        this.alert = true;
      }
    }
  }

  formatConversationDate(conversationDate: Date) {
    return moment(conversationDate).format('DD-MM-YYYY HH:mm');
  }

  loadHistoryMessageContentFromChatRequest(history: ChatRequest): any {
    const message = {
      role: "user",
      text: history.messages[0].text
    }
    this.currentMessageId++
    return message
  }

  async loadHistoryMessageContentFromChatGeneratorResponse(history: ChatGeneratorResponse): Promise<any> {
    const responseMessage = await this.processResponseMessage(history) as MessageContent
    const message = {
      role: responseMessage.role,
      html: responseMessage.html ? responseMessage.html : "Nessuna risposta"
    }
    return message
  }

  async loadConversation(conversation: Conversation) {
    try {
      this.loading = true;
      this.newConversation();
      const storedConversation = await JnAiService.getUserConversation(conversation.conversationId, this.jwtToken)
      for (const conversationItem of this.conversations) {
        conversationItem.selected = false;
      }
      conversation.selected = true;
      this.currentConversation = conversation

      conversation.history = storedConversation.history

      const history = (conversation.history as unknown) as History[]
      const historyMessages: any[] = []
      if (history) {
        for (const reqRes of history) {
          if (isChatRequest(reqRes)) {
            historyMessages.push(this.loadHistoryMessageContentFromChatRequest(reqRes))
          }
          else {
            historyMessages.push(await this.loadHistoryMessageContentFromChatGeneratorResponse(reqRes))
          }
        }
        this.history = historyMessages
      }

      this.alertMessage = "Conversazione '" + conversation.title + "' caricata";
      this.alert = true;
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.paywallOk = false;
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nel caricamento della conversazione '" + conversation.conversationId + "': " + error;
        this.alert = true;
      }
    }
    finally {
      this.loading = false;
    }
  }

  newConversation() {
    this.currentConversation = undefined;
    this.connect.additionalBodyProps.conversation_id = undefined;
    this.currentMessageId = 0
    const chat = this.$refs.chat as DeepChat;
    chat.clearMessages();
    this.documents = []
    //this.test()
  }

  // ACTIONS
  async documentAction(event: any) {
    const [docId, docSource, docTitle, docCategory, action] = event.target.id.split("#")
    this.documentAnalysisRequest = true
    this.selectedDocumentForAnalysis = new DocumentSource(docId, docSource)
    const chat = this.$refs.chat as DeepChat;
    if (action == "summarize") {
      this.promptTemplate = "legal.summarization_generic"
      // In base alla categoria, utilizzo il template di summarization adeguato
      switch (docCategory) {
        case CategoriesJuranet.DCCASS:
          this.promptTemplate = "legal.cassation_sentence_analyzer";
          break;
      }
      this.maxTokens = 4096
      chat.submitUserMessage({ text: "Sintetizza il documento '" + docTitle + "'" });
    }
    else if (action == "maximise") {
      this.maxTokens = 1024
      this.promptTemplate = "legal.maximizer";
      chat.submitUserMessage({ text: "Massimizza il documento '" + docTitle + "'" });
    }
  }

  async copyAnswerToClipboard(event: any) {
    const messageId = event.target.id.split("#")[0] as number
    const chat = this.$refs.chat as DeepChat;
    const messages = chat.getMessages()
    const message = messages[messageId]

    if (message.html) {
      const doc = this.parser.parseFromString(message.html, 'text/html');
      const textDiv = doc.getElementById(messageId + '#text');
      if (textDiv && textDiv.textContent) {
        await navigator.clipboard.writeText(textDiv.textContent)
        this.alertMessage = "Testo copiato"
        this.alert = true
      }
      else {
        this.alertMessage = "Testo da copiare non trovato"
        this.alert = true
      }
    }
  }

  getWordFileName(promptTemplate: string, document: Document): string {
    switch (promptTemplate) {
      case "legal.cassation_sentence_analyzer":
      case "legal.summarization_generic":
        return "SINTESI - " + document.title + ".docx"
      case "legal.maximizer":
        return "MASSIME - " + document.title + ".docx"
    }
    return document.title + ".docx"
  }


  async exportWord(event: any) {
    this.loading = true
    const messageId = event.target.id.split("#")[0] as number
    const chat = this.$refs.chat as DeepChat;
    const messages = chat.getMessages()
    const message = messages[messageId]
    if (message.html) {
      const doc = this.parser.parseFromString(message.html, 'text/html');
      const divs = doc.querySelectorAll('div');
      const exportDivs = Array.from(divs).filter(div => div.id.endsWith('#worddoc'));
      const [messageId, id, source, promptTemplate, verified, type] = exportDivs[0].id.split("#")
      const document = await this.getDocument(id, source, false)

      if (document) {
        const sign = "©" + moment().format('YYYY') + ' - Juranet s.r.l - Riproduzione Riservata';
        const disclaimer =
          "<br/><br/><hr class='solid'><div style='text-align: center;'>Testo generato da JuraNews AI e " +
          (verified ? "verificato" : "non verificato") +
          " dalla redazione</div><div style='text-align: center;'>" + sign + '</div></hr>'
        const text = `<html lang="it"><head><meta charset="UTF-8"><title>` + document?.title + `</title></head><body>` +
          exportDivs[0].innerHTML + disclaimer +
          `</body></html>`
        //const blob = await asBlob(text);
        const blob = await htmlToDocx(text, "", {}, "")

        saveAs(blob as Blob, this.getWordFileName(promptTemplate, document));
      }
    }
    this.loading = false
  }

  async sendPositiveAnswerFeedback(event: any) {
    this.idForFeedback = event.target.id.split("#")[0]
    try {
      this.loading = true;
      await JnAiService.sendGenerationFeedback(this.idForFeedback!, 5, "", this.jwtToken)
      this.alertMessage = "Grazie della tua valutazione!";
      this.alert = true
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.paywallOk = false;
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nell'invio della valutazione: " + error;
        this.alert = true;
      }
    }
    this.loading = false;
  }
  async showNegativeAnswerFeedback(event: any) {
    this.showNegativeFeedback = true
    this.idForFeedback = event.target.id.split("#")[0]
    this.analysisFeedback = false
  }
  async sendNegativeAnswerFeedback(event: any) {
    this.showNegativeFeedback = false
    this.idForFeedback = event.target.id.split("#")[0]
    try {
      this.loading = true;
      await JnAiService.sendGenerationFeedback(this.idForFeedback!, this.rating, this.ratingComment, this.jwtToken)
      this.alertMessage = "Grazie della tua valutazione!";
      this.alert = true
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.paywallOk = false;
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nell'invio della valutazione: " + error;
        this.alert = true;
      }
    }
    this.loading = false;
  }


  async sendPositiveAnalysisFeedback(event: any) {
    this.idForFeedback = event.target.id.split("#")[0]
    try {
      this.loading = true;
      await JnAiService.sendAnalysisFeedback(Number(this.idForFeedback), 5, "", this.jwtToken)
      this.alertMessage = "Grazie della tua valutazione!";
      this.alert = true
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.paywallOk = false;
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nell'invio della valutazione: " + error;
        this.alert = true;
      }
    }
    this.loading = false;
  }
  async showNegativeAnalysisFeedback(event: any) {
    this.showNegativeFeedback = true
    this.idForFeedback = event.target.id.split("#")[0]
    this.analysisFeedback = true
  }

  async sendNegativeFeedback() {
    this.showNegativeFeedback = false
    try {
      this.loading = true;
      if (this.analysisFeedback) {
        await JnAiService.sendAnalysisFeedback(Number(this.idForFeedback!), this.rating, this.ratingComment, this.jwtToken)
      }
      else {
        await JnAiService.sendGenerationFeedback(this.idForFeedback!, this.rating, this.ratingComment, this.jwtToken)
      }
      this.alertMessage = "Grazie della tua valutazione!";
      this.alert = true
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.paywallOk = false;
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nell'invio della valutazione: " + error;
        this.alert = true;
      }
    }
    this.rating = 5
    this.ratingComment = ""
    this.loading = false;
  }
  cancelSendNegativeFeedback() {
    this.showNegativeFeedback = false;
    this.rating = 5;
    this.ratingComment = "";
  }


  // LIFECYCLE
  async initCombo() {
    const categorieRaw = JSON.parse(
      await EsService.getOptions('categorie-banca-dati-juranet')
    );
    for (const c of categorieRaw) {
      if (!c.id.includes('|')) {
        this.categorie.push(new Picklist({ value: "giurisprudenza", label: "Giurisprudenza" }));
   //     this.categorie.push(new Picklist({ value: "normativa", label: "Normativa e prassi" }));
      }
    }

    this.optCategoria = this.categorie[0];
  }

  async created() {
    this.testata = JnService.getTestata(Sections.AI_CHAT);
  }

  async mounted() {
    this.loading = true;
    try {
      EventBus.$on("logged-paywall-ok", async () => {
        this.jwtToken = String(this.$cookies.get("jwtToken"));
        await this.updateConversations()
        this.paywallOk = true;
      });
      EventBus.$on("logged-paywall-not-ok", () => {
        this.paywallOk = false;
        EventBus.$emit("check-authentication")

        this.documents = []
      });

      await this.initCombo();

      const usernameFromCookie = this.$cookies.get("username")!;
      this.jwtToken = String(this.$cookies.get("jwtToken"));


      if (usernameFromCookie && this.jwtToken) {
        const user = await JnService.getCrmUser(
          String(usernameFromCookie),
          undefined
        );
        const purchase = await JnService.getActivePurchaseOrder(user.id);
        let crmInfo = undefined;
        if (purchase) {
          this.paywallOk = true;
          const service = await JnService.getService(purchase.serviceId);
          crmInfo = new CrmInfo(user, purchase, service);
          await this.updateConversations()
          if (this.conversations.length > 0) {
            this.loadConversation(this.conversations[0])
          }
        } else {
          this.paywallOk = false;
          crmInfo = new CrmInfo(user, undefined, undefined);
        }
        this.$store.commit("crmInfo", crmInfo);
      }
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.paywallOk = false;
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nel caricamento";
        this.alert = true;
      }
    }
    this.loading = false;
  }

}

